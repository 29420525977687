function Landing() {
  return (
    <div className="flex bg-blue flex-col w-full ">
      <div
        style={{
          backgroundImage: `url(https://d1n4uhohei7769.cloudfront.net/welcome_gate.png)`,
        }}
        className="landingbg pl-10 flex justify-center items-center pb-12 gap-12 flex-col"
      >
        <a
          href="/home"
          className="text-lg md:text-xl  bg-opacity-50 bg-purple px-4 py-1  font-medium mb-14 mr-8 text-white"
        >
          ENTER
        </a>
      </div>
    </div>
  );
}

export default Landing;
