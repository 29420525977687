// Core modules imports are same as usual

import sui1 from "../../assets/sea/Sui.JPG";
import sui2 from "../../assets/sea/Sui.PNG";
import sui3 from "../../assets/sea/Sui2.JPG";
import sui4 from "../../assets/sea/Sui2.PNG";
import sui5 from "../../assets/sea/Sea_Uman_5.PNG";
import sui6 from "../../assets/sea/Sea_Uman_6.PNG";

function Sea() {
  return (
    <div className="flex max-w-6xl w-full items-center gap-10 flex-col">
      <div className="bg-purple p-3 mt-8 rounded w-full max-w-4xl text-white bg-opacity-10">
        Sea Umans is a limited NFT collection of 1/1s on Sui Network.
        <br />
        <br />
        Sea Umans inhabit the depths of the coral reef wall, beside the Utree
        shore. They thrive in the vast ocean depths, where they’ve established
        their own underwater realm within the rainforest of the sea. These
        beings safeguard the mysteries of the deep waters in the World of Umans,
        where a lot of mystic energy is stored.
        <br />
        <br />
        Sea Umans are serene and empathetic creatures, possessing a deep
        understanding of the emotions of those around them. They communicate via
        telepathy within themselves underwater, fostering a profound connection
        between them. Living peacefully within themselves and with everyone in
        the World of Umans, Sea Umans emerge very little, but when they do, it’s
        only at moonlight.
      </div>
      <div className="flex justify-end w-full max-w-4xl">
        <button
          onClick={() =>
            window.open(
              "https://www.tradeport.xyz/sui/collection/0x4dea41637502f0668e651e676307fae5b0a0affdd95c56cc6e8dfce123db8d12?bottomTab=trades&tab=items"
            )
          }
          className="px-4 py-2 self-end font-bold text-white w-32 bg-purple bg-opacity-40"
        >
          BUY NOW
        </button>
      </div>
      <div className="flex justify-center gap-10 flex-wrap">
        <div className="flex bg-blue bg-opacity-10 p-5">
          <img className="w-96" src={sui1} alt="unique" />
        </div>

        <div className="flex  bg-blue bg-opacity-10 p-5 ">
          <img className="w-96" src={sui2} alt="unique" />
        </div>
        <div className="flex bg-blue bg-opacity-10 p-5 ">
          <img className="w-96" src={sui4} alt="unique" />
        </div>
        <div className="flex bg-blue bg-opacity-10 p-5">
          <img className="w-96" src={sui3} alt="unique" />
        </div>


        <div className="flex bg-blue bg-opacity-10 p-5">
          <img className="w-96" src={sui5} alt="unique" />
        </div>

        <div className="flex bg-blue bg-opacity-10 p-5 ">
          <img className="w-96" src={sui6} alt="unique" />
        </div>
      </div>
    </div>
  );
}

export default Sea;
