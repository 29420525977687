// Core modules imports are same as usual

import air from "../../assets/fairies/air.jpg";
import earth from "../../assets/fairies/earth.jpg";
import ether from "../../assets/fairies/ether.jpg";
import fire from "../../assets/fairies/fire.jpg";
import water from "../../assets/fairies/water.jpg";

function Umans() {
  return (
    <div className="flex max-w-6xl w-full items-center gap-10 flex-col">
      <div className="bg-purple p-3 mt-8 rounded w-full max-w-4xl text-white bg-opacity-10">
        Uman fairies or Uman spirits are the protectors of the Uman world. They
        are born with ancient knowledge about healing the world they live in.
        <br />
        <br />
        There are five different type of spirits: Ether, Air, Fire, Water, and
        Earth. These fairies are the heart and soul of living nature in the
        World of Umans.
      </div>

      <div className="flex justify-end w-full max-w-4xl">
        <button
          onClick={() =>
            window.open("https://opensea.io/collection/umanfairies")
          }
          className="px-4 py-2 self-end font-bold text-white w-32 bg-purple bg-opacity-40"
        >
          BUY NOW
        </button>
      </div>

      <div
        style={{ minHeight: 300 }}
        className="flex md:flex-row flex-col w-full bg-blue bg-opacity-10 p-5 max-w-4xl gap-5"
      >
        <img className="w-80" src={ether} alt="unique" />
        <div className="flex w-full justify-between rounded  bg-blue bg-opacity-30 p-10 text-white flex-col">
          <div className="flex flex-col  gap-4 ">
            <span className="text-xl uppercase italic font-semibold">
              Ether
            </span>
            <span>
              Often referred to as space, Ether it is the essence of emptiness.
              It is the space the other elements fill. It’s the material that
              fills the region of the universe beyond the terrestrial sphere.
              <br />
              <br />
              Ether Fairies take care of the synergy between all the elements.
              These fairies help Umans connect to spirit, intuition, other
              realms and planes.
            </span>
          </div>
        </div>
      </div>

      <div
        style={{ minHeight: 300 }}
        className="flex md:flex-row flex-col w-full bg-blue bg-opacity-10 p-5 max-w-4xl gap-5"
      >
        <img className="w-80" src={air} alt="unique" />
        <div className="flex w-full justify-between rounded  bg-blue bg-opacity-30 p-10 text-white flex-col">
          <div className="flex flex-col  gap-4 ">
            <span className="text-xl uppercase italic font-semibold">air</span>
            <span>
              Air evolves from Ether. When the potential within empty space
              becomes active, the result is Air. It represents the capacity for
              motion and all forces and movements which occur as a result.
              <br />
              <br />
              Air fairies are the guardians of the winds and controllers of the
              weather. Air spirits are concerned with the spiritual life,
              freedom, and purity.
            </span>
          </div>
        </div>
      </div>

      <div
        style={{ minHeight: 300 }}
        className="flex md:flex-row flex-col w-full rounded bg-blue bg-opacity-10 p-5 max-w-4xl gap-5"
      >
        <img className="w-80" src={fire} alt="unique" />
        <div className="flex w-full justify-between rounded  bg-blue bg-opacity-30 p-10 text-white flex-col">
          <div className="flex flex-col  gap-4 ">
            <span className="text-xl italic uppercase font-semibold">Fire</span>
            <span>
              Ether provides fire the space to burn while air provides fire the
              capacity to burn. Fire evolves from Ether and Air.
              <br />
              <br />
              Spirits of Fire are concerned with creativity, life energy and the
              spirit. Fire Fairies give Umans the power of energy, igniting
              action, animation and movement. Fire sparks courage and acts of
              bravery. Fire is the power of inner sight and creative vision.
            </span>
          </div>
        </div>
      </div>

      <div
        style={{ minHeight: 300 }}
        className="flex md:flex-row flex-col w-full bg-blue bg-opacity-10 p-5 max-w-4xl gap-5"
      >
        <img className="w-80" src={water} alt="unique" />
        <div className="flex w-full justify-between rounded  bg-blue bg-opacity-30 p-10 text-white flex-col">
          <div className="flex flex-col  gap-4 ">
            <span className="text-xl uppercase italic font-semibold">
              Water
            </span>
            <span>
              Water is the element that comes into existence out of the previous
              three. Fire causes air to become dense, giving us the element of
              Water. Water is the protector of the body providing its most basic
              nourishment.
              <br />
              <br />
              Water Fairies are usually also spirits of love and the moon,
              drawing the tides, rivers, dew and flow of Uman life. Water
              fairies have the power of sustaining, bestowing and even restoring
              life.
            </span>
          </div>
        </div>
      </div>

      <div
        style={{ minHeight: 250 }}
        className="flex md:flex-row flex-col w-full bg-blue bg-opacity-10 p-5 max-w-4xl gap-5"
      >
        <img className="w-80" src={earth} alt="unique" />
        <div className="flex w-full justify-between rounded  bg-blue bg-opacity-30 p-10 text-white flex-col">
          <div className="flex flex-col  gap-4 ">
            <span className="text-xl uppercase italic font-semibold">
              Earth
            </span>
            <span>
              The Earth element represents the matter of the universe. It gives
              form to the Uman body and all creation.
              <br />
              <br />
              The powers of earth Fairies are concerned with what is manifest,
              the material, the fixed, the solid, the practical, with what is
              rooted. Earth spirits are also concerned with manifestation,
              health, practicality, stability, grounding and centering. They
              help Umans stay connected to who they are, to their past, to their
              ancestors, to their land.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Umans;
