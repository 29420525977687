function Hamburger({ onClick }) {
  return (
    <button
      style={{ top: 38, left: 20 }}
      onClick={() => onClick(true)}
      className="md:hidden flex p-8 flex-col "
    >
      <span className="bg-purple rounded" style={{ width: 22, height: 2 }} />
      <span
        className="bg-purple rounded"
        style={{ width: 22, height: 2, marginBottom: 6, marginTop: 6 }}
      />
      <span className="bg-purple rounded" style={{ width: 22, height: 2 }} />
    </button>
  );
}

export default Hamburger;
