// Core modules imports are same as usual
import { Autoplay } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import uman2 from "../../assets/bit/buman2.png";
import uman1 from "../../assets/bit/buman3.png";
import uman4 from "../../assets/bit/buman4.png";
import uman5 from "../../assets/bit/buman5.png";
import uman6 from "../../assets/bit/buman6.png";
import uman7 from "../../assets/bit/buman7.png";
import uman8 from "../../assets/bit/buman8.png";
import uman9 from "../../assets/bit/buman9.png";
import uman10 from "../../assets/bit/buman10.png";
import uman11 from "../../assets/bit/buman1.png";

function Umans() {
  return (
    <div className="flex max-w-6xl w-full items-center gap-16 flex-col">
      <div className="bg-purple p-3 mt-8 rounded w-full max-w-3xl text-white bg-opacity-10">
        Pixel Umans is a collection of 500 unique Pixel Umans NFTs living on the
        Fantom Network. These are the rarest and most sought-after Umans of all.
        Bit Umans are the stars of the silver screen in the Uman world. Any time
        the Umans flip on a TV set, it is the Bit Umans that they are watching.
      </div>
      <div className="flex pl-0 w-full justify-center">
        <Swiper
          slidesPerView={5}
          loop={true}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay]}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          className="items-center"
        >
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman1} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman2} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman4} alt="uman" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman5} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman6} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman7} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman8} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman9} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman10} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman11} alt="uman" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <button
        onClick={() =>
          window.open(
            "https://paintswap.finance/marketplace/collections/0x8c2fcd5d857ee9aa19d1a27ae81ab1129385e3ac"
          )
        }
        className="px-4 py-2 self-end font-bold text-white w-32 bg-purple bg-opacity-40"
      >
        BUY NOW
      </button>
    </div>
  );
}

export default Umans;
