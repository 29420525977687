// Core modules imports are same as usual
import { Autoplay } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import uman2 from "../../assets/ancestral/auman_2.png";
import uman1 from "../../assets/ancestral/auman_3.png";
import uman4 from "../../assets/ancestral/auman_4.png";
import uman5 from "../../assets/ancestral/auman_5.png";
import uman6 from "../../assets/ancestral/auman_6.png";
import uman7 from "../../assets/ancestral/auman_7.png";
import uman8 from "../../assets/ancestral/auman_8.png";
import uman9 from "../../assets/ancestral/auman_9.png";
import uman10 from "../../assets/ancestral/auman_10.png";
import uman11 from "../../assets/ancestral/auman_11.png";
import uman12 from "../../assets/ancestral/auman_12.png";

import leader1 from "../../assets/ancestral/abka.jpg";
import leader2 from "../../assets/ancestral/jigari.jpg";
import leader3 from "../../assets/ancestral/shamanka.jpg";

function Umans() {
  return (
    <div className="flex max-w-6xl w-full items-center gap-16 flex-col">
      <div className="bg-purple p-3 mt-8 rounded flex flex-col w-full max-w-3xl text-white bg-opacity-10">
        <span>
          Ancestral Umans is a collection of 3000 unique NFTs living in the
          Fantom blockchain.
          <br />
        </span>
        <span>
          They are the ancient kin to the Modern Umans, who crossed into the
          Fantom dimension eons ago. They were led there by the Tribune:
          Shamanka, the Healer; Abka, the Peacekeeper; Jigari, the Protector.
        </span>
      </div>
      <div className="flex gap-10 flex-col w-full">
        <div className="flex pl-0 w-full justify-center">
          <Swiper
            slidesPerView={5}
            loop={true}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay]}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 5,
                spaceBetween: 30,
              },
            }}
            className="items-center"
          >
            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman1} alt="uman" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman2} alt="uman" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman4} alt="uman" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman5} alt="uman" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman6} alt="uman" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman7} alt="uman" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman8} alt="uman" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman9} alt="uman" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman10} alt="uman" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman11} alt="uman" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
                <img className="w-48" src={uman12} alt="uman" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <button
          onClick={() =>
            window.open(
              "https://paintswap.finance/marketplace/collections/0x5dbc2a8b01b7e37dfd7850237a3330c9939d6457"
            )
          }
          className="px-4 py-2 self-end font-bold text-white w-32 bg-purple bg-opacity-40"
        >
          BUY NOW
        </button>
      </div>

      <div className="flex gap-10 flex-col w-full">
        <div className="flex flex-wrap gap-8 pl-0 w-full justify-center">
          <div className="flex w-52 rounded p-3 bg-opacity-50 bg-blue flex-col">
            <img className="w-48 mb-2" src={leader3} alt="uman" />
            <span className="font-semibold text-white text-center">
              Ancestral Uman Leader
            </span>
            <span className="text-white font-semibold text-center">
              Shamanka the Healer
            </span>
          </div>
          <div className="flex w-52 rounded p-3 bg-opacity-50 bg-blue flex-col">
            <img className="w-48 mb-2" src={leader1} alt="uman" />
            <span className="font-semibold text-white text-center">
              Ancestral Uman Leader
            </span>
            <span className="text-white font-semibold text-center">
              Abka the Peacekeeper
            </span>
          </div>
          <div className="flex w-52 rounded p-3 bg-opacity-50 bg-blue flex-col">
            <img className="w-48 mb-2" src={leader2} alt="uman" />
            <span className="font-semibold text-white text-center">
              Ancestral Uman Leader
            </span>
            <span className="text-white font-semibold text-center">
              Jigari the Protector
            </span>
          </div>
        </div>
        <button
          onClick={() =>
            window.open(
              "https://paintswap.finance/marketplace/collections/0x972dd206a7c2d4ae46db1db700bc79de1bc59960"
            )
          }
          className="px-4 py-2 self-end font-bold text-white w-32 bg-purple bg-opacity-40"
        >
          BUY NOW
        </button>
      </div>
    </div>
  );
}

export default Umans;
