import { useHistory } from "react-router-dom";
import cs from "classnames";
import x from "../assets/components/x.svg";

function MobileHeader({ setShow }) {
  const history = useHistory();

  return (
    <div
      className={cs(
        "z-50 bg-purple fixed top-0 bottom-0 flex flex-col left-0 right-0"
      )}
    >
      <button
        onClick={() => setShow(false)}
        className="outline-none z-50 self-end justify-self-end pr-8 pt-8 text-xl"
      >
        <img alt="x" className="w-6 cursor-pointer " src={x} />
      </button>
      <div className="flex pt-20 -mt-10 flex-col justify-start h-full items-center">
        <div className="max-w-7xl gap-3 pt-8 flex w-full flex-col">
          <button
            onClick={() => {
              history.push("/home");
            }}
            className="text-white "
          >
            HOME
          </button>
          <button
            onClick={() => {
              window.open(
                "https://b9e81fef-4b56-4ec6-832c-e9deddfd04ae.filesusr.com/ugd/6e7def_f2bd1a551ed846b9b0c25e89bd274378.pdf",
                "_self"
              );
            }}
            className="text-white "
          >
            STORY
          </button>
          <button
            onClick={() => {
              window.open("https://store.worldofumans.com/", "_self");
            }}
            className="text-white "
          >
            STORE
          </button>
          <button
            onClick={() => {
              history.push("/artist");
            }}
            className="text-white "
          >
            ARTIST
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
