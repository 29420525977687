import React, { useEffect } from "react";
import "./App.css";
import BaseLayout from "./layout/BaseLayout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Artist from "./pages/Artist/Artist";
import Landing from "./pages/Landing";

function App() {
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.removeAllListeners();
      window.ethereum.on("chainChanged", () => {
        document.location.reload();
      });
    }
  });

  function renderApp() {
    return (
      <>
        <Switch>
          <Route exact path="/artist">
            <BaseLayout>
              <Artist />
            </BaseLayout>
          </Route>
          <Route exact path="/home">
            <BaseLayout>
              <Home />
            </BaseLayout>
          </Route>
          <Route exact path="*">
            <BaseLayout>
              <Landing />
            </BaseLayout>
          </Route>
        </Switch>
      </>
    );
  }

  return <BrowserRouter>{renderApp()}</BrowserRouter>;
}

export default App;
