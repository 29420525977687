import Header from "../../layout/Header";

function Artist() {
  return (
    <div className="flex bg-blue flex-col w-full ">
      <div
        style={{
          backgroundImage: `url(https://d1n4uhohei7769.cloudfront.net/abouttheartist.jpg)`,
        }}
        className="mainbg2 flex items-center pb-12 gap-3 flex-col"
      >
        <Header />

        <div className="h-full flex items-center justify-center"></div>
        <div className="max-w-5xl text-lg text-white gap-3 p-8 flex  flex-col  justify-between w-full">
          <div className="flex flex-col">
            <span className="text-lg">Artist</span>
            <span className="italic text-xl mb-4 font-bold uppercase">
              Uman
            </span>
          </div>

          <div className="video-responsive">
            <iframe
              width="100%"
              height="280"
              src={`https://www.youtube.com/embed/iyzDFiHQAKI?si=nD7qPFlF0H75W6qE`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
          <span className="bg-blue mt-3 p-2 bg-opacity-30">
            Artist is a stimulating label that connects my creation with your
            sensitivity, what I produce with what you feel. It is the link
            between you and me. Amongst this mutual interaction between creation
            and reception is where the label: artist, resides. Nowhere else
            because without any of the parts, there would be no such thing as
            art, let alone… an artist. Be that as it may, I have never
            considered myself one. For me, it is just who I am. My constant
            state of thinking, creating and being… in no particular order. I
            make art for myself, my expression, and to cope with life as it
            comes. It is from this ego-timid but confident stance that I do what
            I do. 
            <br />
            <br />
            I was born in Bogotá and raised in Quito, Ecuador. I earned a BFA
            from Parsons School of Design in NYC, and an MA in Contemporary Art
            from the Royal College of Art in London. My artwork envisions
            alternate futures, exploring existential questions about identity
            and purpose through engaging with auto-fiction. Influenced by
            Ecuadorian artists Eduardo Kingman and Oswaldo Guayasamin, I blend
            Indigenism with Psychedelia, creating a unique style that reflects
            my cultural roots and pushes artistic boundaries.
            <br />
            <br />
            My characters, known as Umans, embody the psychedelic and spiritual
            based on my travels and personal experiences. They seek to
            articulate life’s multi-perspective nature and narrate one story
            through various lenses. Recognizing that no culture is fully
            understood from a one-sided interpretation and no story is an
            absolute truth, I approach narratives with a dynamic perspective.
            Characterized by high contrast and vibrant colors, my pieces convey
            tranquility, serenity, bliss, and free-spiritedness, visually
            expressing my identity and emotions.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Artist;
