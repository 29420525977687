import worldofumans from "../../assets/worldofumans.svg";

import Umans from "./Umans";
import AUmans from "./AncestralUmans";
import BUmans from "./BitUmans";
import Unique from "./Unique";

import { useState } from "react";
import Header from "../../layout/Header";
import Fairies from "./Fairies";
import Sea from "./Sea";

function Home() {
  const [screen, setScreen] = useState("umans");

  return (
    <div className="flex bg-blue flex-col w-full ">
      <div
        style={{
          backgroundImage: `url(https://d1n4uhohei7769.cloudfront.net/background.jpg)`,
        }}
        className="mainbg px-10 flex items-center pb-12 gap-12 flex-col"
      >
        <Header />
        <div className="max-w-7xl gap-8 flex w-full flex-col">
          <img src={worldofumans} className="w-96" alt="title" />
          <span className="text-white leading-5 max-w-md">
            Umans are a psychedelic, spiritual species found deep in the reaches
            of space and across the boundaries of time and dimension. Together,
            the varieties of Uman paint is a picture of an Empathetic Universe,
            kinder and more peaceful than that which we know. Currently, Umans
            are confirmed as present in the Ethereum and Fantom dimensions.
            <br />
            <br /> The first Umans discovered populate the Ether dimension, and
            are well known for their solidarity with nature and their pursuit of
            Ataraxia. They number 999. Never any more; never any less. <br />
            <br /> Their genealogical predecessors, the Ancestral Umans, can be
            found in the Fantom dimension. They relocated there long ago.
            Ancestral Umans are lovingly preoccupied with Balance, from a cosmic
            to a microcosmic scale. Few creatures are as beautiful as the
            Ancestral Umans.
            <br />
            <br /> The rarest and most sought-after Umans of all are the Bit
            Umans. Bit Umans are the stars of the silver screen in the Uman
            world. Any time the Umans flip on a TV set, it is the Bit Umans that
            they are watching.
          </span>
        </div>
        <div className="max-w-7xl justify-between pt-0 flex w-full text-white">
          <div className="gap-6 h-10 flex">
            <a
              href="https://b9e81fef-4b56-4ec6-832c-e9deddfd04ae.filesusr.com/ugd/6e7def_f2bd1a551ed846b9b0c25e89bd274378.pdf"
              className="px-4 text-center py-2 bg-opacity-60 w-32 md:w-44 bg-purple"
            >
              READ MORE
            </a>
            <button
              onClick={() => window.location.replace("/home#collections")}
              className="px-4 py-2 bg-opacity-60 w-32 md:w-44 bg-purple"
            >
              EXPLORE
            </button>
          </div>
          <div className="hidden gap-10 pt-8 md:flex text-white">
            <div className="flex flex-col items-center">
              <span className="text-3xl">4k+</span>
              <span>Unique Artworks</span>
            </div>
            <div className="bg-purple w-1 bg-opacity-60" />
            <div className="flex flex-col items-center">
              <span className="text-3xl">6</span>
              <span>Collections</span>
            </div>
            <div className="bg-purple w-1 bg-opacity-60" />
            <div className="flex flex-col items-center">
              <span className="text-3xl">1</span>
              <span>Artist</span>
            </div>
          </div>
        </div>
      </div>
      <div
        id="collections"
        style={{
          backgroundImage: `url(https://d1n4uhohei7769.cloudfront.net/collections_background.PNG)`,
        }}
        className="mainbg pt-20 flex items-center gap-12 flex-col"
      >
        <div
          style={{ maxWidth: "85rem" }}
          className="gap-5 p-16 px-8 bg-purple rounded-lg bg-opacity-5 h-full items-center flex-col flex w-full"
        >
          <div className=" gap-6  h-full justify-between flex md:flex-row flex-col w-full">
            <button
              onClick={() => setScreen("umans")}
              style={{}}
              className={`text-5xl text-opacity-80 ${
                screen === "umans" ? "text-activeMenu" : "text-inactiveMenu"
              } chicle-regular`}
            >
              umans
            </button>

            <button
              onClick={() => setScreen("ancestral")}
              style={{}}
              className={`text-5xl text-opacity-80 ${
                screen === "ancestral" ? "text-activeMenu" : "text-inactiveMenu"
              } chicle-regular`}
            >
              ancestral umans
            </button>

            <button
              onClick={() => setScreen("bit")}
              style={{}}
              className={`text-5xl text-opacity-80 ${
                screen === "bit" ? "text-activeMenu" : "text-inactiveMenu"
              } chicle-regular`}
            >
              bit umans
            </button>

            <button
              onClick={() => setScreen("fairies")}
              style={{}}
              className={`text-5xl text-opacity-80 ${
                screen === "fairies" ? "text-activeMenu" : "text-inactiveMenu"
              } chicle-regular`}
            >
              uman fairies
            </button>

            <button
              onClick={() => setScreen("sea")}
              style={{}}
              className={`text-5xl text-opacity-80 ${
                screen === "sea" ? "text-activeMenu" : "text-inactiveMenu"
              } chicle-regular`}
            >
              sea umans
            </button>

            <button
              onClick={() => setScreen("unique")}
              style={{}}
              className={`text-5xl text-opacity-80 ${
                screen === "unique" ? "text-activeMenu" : "text-inactiveMenu"
              } chicle-regular`}
            >
              other pieces
            </button>
          </div>
          {screen === "umans" && <Umans />}
          {screen === "ancestral" && <AUmans />}
          {screen === "bit" && <BUmans />}
          {screen === "fairies" && <Fairies />}
          {screen === "unique" && <Unique />}
          {screen === "sea" && <Sea />}
        </div>
      </div>
    </div>
  );
}

export default Home;
