import { useHistory } from "react-router-dom";
import Hamburger from "../components/Hamburger";
import discord from "../assets/discord.png";
import twitter from "../assets/twitter.png";
import intragram from "../assets/instagram.png";

function MobileHeader({ setShow, show }) {
  const history = useHistory();

  return (
    <header className="flex w-full justify-center ">
      <nav className="flex max-w-7xl items-center justify-between w-full">
        <div className="md:flex hidden max-w-7xl gap-3 pt-8  w-full flex-col">
          <div className="flex gap-14">
            <button
              onClick={() => {
                history.push("/home");
              }}
              className="text-white "
            >
              HOME
            </button>
            <button
              onClick={() => {
                window.open(
                  "https://b9e81fef-4b56-4ec6-832c-e9deddfd04ae.filesusr.com/ugd/6e7def_f2bd1a551ed846b9b0c25e89bd274378.pdf",
                  "_self"
                );
              }}
              className="text-white "
            >
              STORY
            </button>
            <button
              onClick={() => {
                window.open("https://store.worldofumans.com/", "_self");
              }}
              className="text-white "
            >
              STORE
            </button>
            <button
              onClick={() => {
                history.push("/artist");
              }}
              className="text-white "
            >
              ARTIST
            </button>
          </div>
          <div className="flex py-3 pt-3.5 items-center gap-12">
            <a href="https://twitter.com/worldofumans">
              <img className="h-4" alt="socials" src={twitter} />
            </a>
            <a href="https://discord.gg/3mQrnrXE6h">
              <img className="h-4" alt="socials" src={discord} />
            </a>
            <a href="https://www.instagram.com/worldofumans">
              <img className="h-5" alt="socials" src={intragram} />
            </a>
          </div>
        </div>
        {!show && (
          <div className="flex pl-4 md:hidden gap-12">
            <a href="https://twitter.com/worldofumans">
              <img className="w-4" alt="socials" src={twitter} />
            </a>
            <a href="https://discord.gg/3mQrnrXE6h">
              <img className="h-4" alt="socials" src={discord} />
            </a>
            <a href="https://www.instagram.com/worldofumans">
              <img className="h-5" alt="socials" src={intragram} />
            </a>
          </div>
        )}
        {!show && <Hamburger onClick={() => setShow(true)} />}
      </nav>
    </header>
  );
}

export default MobileHeader;
