// Core modules imports are same as usual

import uman1 from "../../assets/unique/5.png";
import uman2 from "../../assets/unique/1.gif";
import uman4 from "../../assets/unique/7.png";
import uman5 from "../../assets/unique/2.PNG";
import uman7 from "../../assets/unique/6.png";
import uman8 from "../../assets/unique/8.png";
import uman9 from "../../assets/unique/9.png";
import uman10 from "../../assets/unique/3.png";
import uman0 from "../../assets/unique/0.jpg";

import uman21 from "../../assets/unique/unique12.PNG";
import uman22 from "../../assets/unique/unique13.PNG";
import uman24 from "../../assets/unique/unique14.PNG";
import uman25 from "../../assets/unique/unique15.PNG";
import uman27 from "../../assets/unique/unique16.PNG";
import uman28 from "../../assets/unique/unique17.jpg";
import uman29 from "../../assets/unique/unique18.PNG";
import uman26 from "../../assets/unique/unique19.PNG";
import uman23 from "../../assets/unique/unique20.PNG";

function Umans() {
  return (
    <div className="flex max-w-6xl w-full items-center gap-16 flex-col">
      <div style={{ columnCount: "2" }} className="columns-2 mt-10 gap-5">
        <div className="inline-block width-full mb-5 text-center bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman0} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/collection/the-nft-nyc-2022-diversity-of-nfts-art-collection?search[query]=uman&search[sortAscending]=true&search[sortBy]=UNIT_PRICE"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0  font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman25} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/ethereum/0x10c75f810a7549b03fec9db9731415f448b20bfd/4"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman2} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55695300558473532380234128748791027067769376062037970576475949638599823065089"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0  font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman21} alt="unique" />
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman1} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55695300558473532380234128748791027067769376062037970576475950301605334614017"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0  font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman10} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55695300558473532380234128748791027067769376062037970576475950963511334535169"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman26} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://paintswap.finance/marketplace/fantom/assets/0xa660df507adbf42c5c09cd6914974915eaebfecb/2"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman27} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://paintswap.finance/marketplace/fantom/assets/0xa660df507adbf42c5c09cd6914974915eaebfecb/1"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman4} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55695300558473532380234128748791027067769376062037970576475950298306799730689"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0  font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        {/*  */}

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman5} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55695300558473532380234128748791027067769376062037970576475950299406311358465"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        {/*  */}

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman22} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/ethereum/0x10c75f810a7549b03fec9db9731415f448b20bfd/5"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman23} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/ethereum/0x10c75f810a7549b03fec9db9731415f448b20bfd/1"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman24} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://superrare.com/0xa9cf3fb2c4538ac95e0c822758ec745fcfed8360/i'm-a-little-of-everything-374"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        {/*  */}

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman7} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55695300558473532380234128748791027067769376062037970576475950971207915929601"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0  font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img
              src={"https://umans-website.s3.amazonaws.com/muvies.jpg"}
              alt="unique"
            />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55695300558473532380234128748791027067769376062037970576475950974506450812929"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        {/*  */}

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman28} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/55695300558473532380234128748791027067769376062037970576475950964610846162945"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman29} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/ethereum/0x10c75f810a7549b03fec9db9731415f448b20bfd/3"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman8} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55695300558473532380234128748791027067769376062037970576475950965710357790721"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0 font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>

        <div className="inline-block width-full mb-5 bg-blue bg-opacity-10 p-5  gap-5">
          <div className="flex flex-col gap-4">
            <img src={uman9} alt="unique" />
            <button
              onClick={() =>
                window.open(
                  "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/55695300558473532380234128748791027067769376062037970576475950966809869418497"
                )
              }
              className="px-4 py-2 self-center mt-6 md:mt-0  font-bold text-white w-32 bg-purple bg-opacity-40"
            >
              BUY NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Umans;
