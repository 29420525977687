// Core modules imports are same as usual
import { Autoplay } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import uman2 from "../../assets/umans/uman2.png";
import uman1 from "../../assets/umans/uman3.png";
import uman3 from "../../assets/umans/uman1.png";
import uman4 from "../../assets/umans/uman4.png";
import uman5 from "../../assets/umans/uman5.png";
import uman6 from "../../assets/umans/uman6.png";
import uman7 from "../../assets/umans/uman7.png";
import uman8 from "../../assets/umans/uman8.png";
import uman9 from "../../assets/umans/uman9.png";
import uman10 from "../../assets/umans/uman10.png";
import uman11 from "../../assets/umans/uman11.png";
import uman12 from "../../assets/umans/uman12.png";
import uman13 from "../../assets/umans/uman13.png";
import uman14 from "../../assets/umans/uman14.png";

function Umans() {
  return (
    <div className="flex max-w-6xl w-full items-center gap-16 flex-col">
      <div className="bg-purple p-3 mt-8 rounded w-full max-w-3xl text-white bg-opacity-10">
        The World of Umans is a collection of 999 unique Umans NFTs living on
        the Ethereum blockchain.
        <br />
        Find these Umans embracing the natural world and it's mind-bending gifts
        under the nearest utree.
      </div>
      <div className="flex pl-0 w-full justify-center">
        <Swiper
          slidesPerView={5}
          loop={true}
          centeredSlides={true}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay]}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          className="items-center justify-center"
        >
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman1} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman2} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman3} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman4} alt="uman" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman5} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman6} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman7} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman8} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman9} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman10} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman11} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman12} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman13} alt="uman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex w-52 rounded p-4 bg-opacity-50 bg-blue flex-col">
              <img className="w-48" src={uman14} alt="uman" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <button
        onClick={() =>
          window.open("https://opensea.io/collection/worldofumans")
        }
        className="px-4 py-2 self-end font-bold text-white w-32 bg-purple bg-opacity-40"
      >
        BUY NOW
      </button>
    </div>
  );
}

export default Umans;
